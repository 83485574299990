const particleConfig = {
	fpsLimit: 120,
	interactivity: {
		modes: {
			push: {
				quantity: 5,
			},
			repulse: {
				distance: 200,
				duration: 0.4,
			},
		},
	},
	particles: {
		color: {
			value: ['#fce5cd', '#e5e5e5'],
		},
		collisions: {
			enable: false,
		},
		move: {
			// direction: 'top',
			enable: true,
			outModes: {
				default: 'out',
			},
			random: true,
			speed: 5,
			straight: false,
		},
		number: {
			value: 10,
		},
		opacity: {
			value: 0.5,
			random: true,
		},
		shape: {
			type: 'circle',
		},
		size: {
			value: { min: 10, max: 153 },
		},
	},
	fullScreen: {
		enable: true,
		zIndex: -1,
	},
	detectRetina: true,
};

export default particleConfig;
