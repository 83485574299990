import '../node_modules/tachyons/css/tachyons.min.css';
import './App.css';
import About from './about/About';
import Name from './name/Name';
import Intro from './intro/Intro';
import Footer from './footer/Footer';
import Quote from './quote/Quote';
import Navbar from './navbar/Navbar';
import Work from './work/Work';
import ParticleBackground from './particles/ParticleBackground';

function App() {
	return (
		<>
			<ParticleBackground />
			<Navbar />
			<Intro />
			<Name />
			<About />
			<Work />
			<Quote />
			<Footer />
		</>
	);
}

export default App;
