import React from 'react';
import { motion } from 'framer-motion';

const Intro = () => {
	return (
		<>
			<article className='vh-100 dt w-100 white ' id='intro'>
				<div className='dtc v-mid tc black ph3 ph4-l'>
					<motion.h1
						initial={{ opacity: 0, y: 110 }}
						whileInView={{ opacity: 1 }}
						viewport={{ once: true }}
						transition={{ delay: 1 }}
						animate={{ y: 0 }}
						className='black b f1 f-headline-ns db mb3 mb4-ns'
					>
						👋 hello
						<span className='dark-pink'>.</span>
					</motion.h1>
				</div>
			</article>
		</>
	);
};

export default Intro;
