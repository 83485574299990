import { motion } from 'framer-motion';
import React from 'react';

const Quote = () => {
	return (
		<article
			className='vh-100 dt w-100'
			styles={{
				// clipPath: 'polygon(0 20%,100% 0,100% 80%,0 0)',
				zIndex: 1,
			}}
		>
			<div className='dtc v-mid tc black ph3 ph4-l '>
				<h1 className='black b f1 f-headline-ns db mb3 mb4-ns'>
					<span className='gold'>"</span>...the{' '}
					<motion.span
						className='black'
						initial={{ color: '#fff' }}
						whileInView={{ color: '#d5008f' }}
						viewport={{ once: true }}
						transition={{ duration: 1 }}
					>
						best
					</motion.span>{' '}
					work comes from
					<motion.span
						className='black'
						initial={{ color: '#fff' }}
						whileInView={{ color: '#d5008f' }}
						viewport={{ once: true }}
						transition={{ duration: 1.5 }}
					>
						{' '}
						people
					</motion.span>{' '}
					who love what they do
					<motion.span
						className='black'
						initial={{ color: '#fff' }}
						whileInView={{ color: '#d5008f' }}
						viewport={{ once: true }}
						transition={{ duration: 2 }}
					>
						{' '}
						.
					</motion.span>
					<span className='gold'>"</span>
				</h1>
			</div>
		</article>
	);
};

export default Quote;
