import React from 'react';
import Skills from '../skills/Skills';
import Social from '../social/Social';
import { motion } from 'framer-motion';

const About = () => {
	return (
		<>
			<article className='cf ph3 ph5-ns pv5 z-max' id='about'>
				<motion.header
					initial={{ opacity: 0 }}
					whileInView={{ opacity: 1 }}
					viewport={{ once: true }}
					transition={{ delay: 0.3 }}
					className='fn fl-ns w-50-ns pr4-ns tc'
				>
					<h1 className='mb3 mt0 lh-title '>
						a byte about <span className='dark-pink'>me</span>
					</h1>
					<div className='pa4'>
						<img
							src='./assets/images/me.png'
							className='br2 h5 w6 dib'
							alt='me'
						/>
					</div>
					<Social />
				</motion.header>
				<motion.div className='fn fl-ns w-50-ns helvetica'>
					<motion.p
						initial={{ y: 100, opacity: 0 }}
						whileInView={{ y: 0, opacity: 1 }}
						viewport={{ once: true }}
						transition={{ delay: 0.5 }}
						className='lh-copy measure mt4 mt0-ns  f6 f5-ns lh-copy measure i pl4 bl bw1 b--gold mb4'
					>
						…It all started in 2014, there was a business calculation which my
						office had to constantly do by hand. I remember it involved a lot of
						different variables and mathematics in order to get a solution. I
						saw myself and my co-workers constantly struggle and spend a
						prolonged amount of time. I knew what I had to do, I just didn't
						know the journey it would take me on.
					</motion.p>
					<motion.p
						initial={{ y: 100, opacity: 0 }}
						whileInView={{ y: 0, opacity: 1 }}
						viewport={{ once: true }}
						transition={{ delay: 0.7 }}
						className='lh-copy measure mt4 mt0-ns  f6 f5-ns lh-copy measure i pl4 bl bw1 b--gold mb4'
					>
						I clearly remember writing my first lines of code, getting stuck,
						finding the issue and fixing it. At that point I realized that there
						was a rewarding feeling that I experienced once the application was
						working. I quickly found out what I wanted to become in life. My
						passion has not changed since then, I've just picked up more skills
						along the way. When I'm not working, I use photography as a source
						of inspiration for looking at the world through a different creative
						perspective.
					</motion.p>
					<p className='lh-copy measure'></p>
				</motion.div>
			</article>

			<Skills />
		</>
	);
};

export default About;
