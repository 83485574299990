export const projects = [
	{
		id: 1,
		title: 'Focus Owl',
		img: '../assets/images/owl.png',
		link: 'https://expo.io/@devaita/focus-owl',
		description:
			'iOS and Android application which helps the user focus on tasks using the Pomodoro technique to invoke 25-minute sessions in which the user can help make the most or his and her time. Built using ReactNative and Expo Tools.',
		source: 'https://github.com/aitaDev/focus-owl',
	},
	{
		id: 2,
		title: 'Violet Evening',
		img: '../assets/images/obsidian2.png',
		link: 'https://github.com/aitaDev/Violet-Evening-for-Obsidian',
		description:
			'A published skin for the popular note taking application Obsidian.md, featuring a dark purple theme for the night owls.',
		source: 'https://github.com/aitaDev/Violet-Evening-for-Obsidian',
	},
	{
		id: 3,
		title: 'Face Detect',
		img: '../../assets/images/brain.jpeg',
		link: 'https://aita.dev/dev/fd/',
		description:
			'This app allows users to submit any picture of any person or face and will use the power of AI to determine if there is a face present in the picture. Built with React, leveraging machine learning RESTful API.',
		source: 'https://github.com/aitaDev/face-detect',
	},
	{
		id: 4,
		title: 'Amazon Clone',
		img: '../../assets/images/amz.jpg',
		link: 'https://clone-a20b3.web.app/',
		description:
			'An Ecommerce app which is a clone to the popular amazon.com website but created in React using Firebase for back end and user authentication.',
		source: null,
	},
	{
		id: 5,
		title: 'Mountain Peak',
		img: '../assets/images/bs.jpeg',
		link: 'https://aita.dev/dev/Mountain-Peak/',
		description:
			'A website for an icy resort, featuring Bootstrap, jQuery, maps API and use of parallax effect. Fully responsive on all devices.',
		source: 'https://github.com/aitaDev/Mountain-Peak',
	},
];
