import React from 'react';

const Footer = () => {
	const year = new Date().getFullYear();
	return (
		<>
			<footer
				id='contact'
				className='pt6 pb2 bg-gold tc white'
				style={{
					clipPath: 'polygon(0 30%,100% 0,100% 100%,0 100%)',
				}}
			>
				<h1 className='white'>lets work together!</h1>
				<a
					href='mailto:dan@aita.dev'
					className='link b f2 f1-ns black lh-solid hover-white'
				>
					dan@aita.dev
				</a>
				<div className='mt5'>
					<p className='f6 db b lh-solid'>made with 💜 from Texas</p>
				</div>
				<div className='mt3'>
					<p className='f6 db b ttu lh-solid'>© {year} Dan Aita</p>
				</div>
			</footer>
		</>
	);
};

export default Footer;
