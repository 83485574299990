import React from 'react';
import { motion } from 'framer-motion';
import { projects } from '../projects/Projects';

const Work = () => {
	return (
		<>
			<article className='pa3 pa5-ns tc' id='work'>
				<motion.h1
					className='f-subheadline-ns pb5'
					initial={{ opacity: 0, y: 110 }}
					whileInView={{ opacity: 1 }}
					viewport={{ once: true }}
					transition={{ duration: 0.5 }}
				>
					💼 Latest Works<span className='dark-pink'>:</span>
				</motion.h1>
			</article>
			<div className='pt-2 ma1 ma5-ns'>
				{projects.map((project) => {
					return (
						<div
							className='bg-white-40 br1 pa3 pa4-ns mv3 ba center ph3-ns mb6'
							style={{
								display: 'flex',
								justifyContent: 'center',
								borderColor: 'rgb(226 207 187)',
								backdropFilter: 'blur(5px)',
							}}
							key={project.id}
						>
							<div style={{ display: 'flex', alignContent: 'space-around' }}>
								<div className='cf '>
									<div className='w-100 mw8' style={{ flex: 1 }}>
										<motion.div
											className='pv4 ns-pa5'
											initial={{ opacity: 0 }}
											whileInView={{ opacity: 1 }}
											viewport={{ once: true }}
											transition={{ duration: 0.5 }}
										>
											<img className='' src={project.img} alt={project.title} />
										</motion.div>
									</div>
									<motion.div
										className='fl pl2'
										initial={{ opacity: 0, y: 110 }}
										whileInView={{ opacity: 1, y: 0 }}
										viewport={{ once: true }}
										transition={{ delay: 0.2 }}
										style={{ flex: 1 }}
									>
										<div className=' mw8 pv4 '>
											<a className='link' href={project.link}>
												<h3 className='f2 f1-m f-headline-l measure-narrow lh-title mv0'>
													<span className='works bg-black-90 lh-copy white pa1 tracked-tight hover-gold'>
														{project.title} <span className='dark-pink'>:</span>
													</span>
												</h3>
											</a>
											<h2 className='f5 f3-l fw6 mb4 mb5-l lh-title black tracked-tight'>
												{project.description}
											</h2>
											{project.source ? (
												<a
													className='no-underline f5 f3-l ink b dark-pink hover-gold'
													href={project.source}
													target='_blank'
													rel='noreferrer'
												>
													View Source
												</a>
											) : (
												''
											)}
										</div>
									</motion.div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default Work;
