import React from 'react';
import Particles from 'react-tsparticles';
import particleConfig from './particle-config';
import { loadFull } from 'tsparticles';

const ParticleBackground = () => {
	const particlesInit = async (main) => {
		await loadFull(main);
	};

	const particlesLoaded = (container) => {
		// console.log(container);
	};

	return (
		<Particles
			id='tsparticles'
			init={particlesInit}
			loaded={particlesLoaded}
			options={particleConfig}
		></Particles>
	);
};

export default ParticleBackground;
