import React from 'react';

const Skills = () => {
	const skillsArr = [
		'React',
		'Git',
		'CSS',
		'NodeJS',
		'MySQL',
		'HTML5',
		'SASS',
		'MaterialUI',
		'Bootstrap',
		'JavaScript',
		'Figma',
		'UI/UX',
		'Linux',
		'React Native',
		'AWS',
		'MongoDB',
		'Express',
		'REST',
		'Firebase',
		'Apache',
		'Expo',
		'PHP',
		'Heroku',
		'Ajax',
		'jQuery',
	];
	return (
		<>
			<article
				className='vh-100 dt w-100 bg-gold'
				id='skills'
				style={{ clipPath: 'polygon(0 20%,100% 0,100% 80%,0 100%)' }} //add in seperate css
			>
				<div className='dtc v-mid tc black ph3 ph4-l '>
					<h3 className='f2 f1-m f-headline-l measure-narrow lh-title mv0'>
						<span className='bg-black-90 lh-copy white pa1 tracked-tight'>
							skills set <span className='gold helvetica'>includes</span>
							<span className='dark-pink'>:</span>
						</span>
					</h3>
					<ul className='list ph3 ph5-ns pv4 center mw8'>
						{skillsArr.map((skill, index) => (
							<li key={index} className='dib mr2 mb2 black helvetica shadow-3'>
								<span className='f6 f4-ns b db pa2 dark-p ba b--black hover-bg-black hover-white bg-animate'>
									{skill}
								</span>
							</li>
						))}
					</ul>
				</div>
			</article>
		</>
	);
};

export default Skills;
