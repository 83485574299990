import React from 'react';

const Navbar = () => {
	return (
		<header
			className='bg-white-90 fixed w-100 ph3 pv2 ph4-m ph5-l'
			style={{ zIndex: 5, display: 'flex', alignContent: 'center' }}
		>
			<nav
				className='f6 fw6 ttu tracked'
				style={{ display: 'flex', alignItems: 'center' }}
			>
				<a className='link dim gold dib mr3' href='#intro' title='Hello'>
					<img src='../apple-touch-icon.png' alt='logo' width='50' />
				</a>
				<a className='link dim dark-pink dib mr3' href='#about' title='About'>
					About
				</a>
				<a className='link dim dark-pink dib mr3' href='#skills' title='Skills'>
					Skills
				</a>
				<a className='link dim  dark-pink dib mr3' href='#work' title='Work'>
					Work
				</a>
				<a className='link dim gold dib' href='#contact' title='Contact'>
					Contact
				</a>
			</nav>
		</header>
	);
};

export default Navbar;
